import { RouteObject, useRoutes } from "react-router-dom";
import { publicRoutes } from "./public";
import { protectedRoutes } from "./protected";
import CheckPermissionToPerformAction from "./protected-component/hasPermissionToPerformAction";
/**
 *
 * @returns Routes Elements
 */

const AppRoutes: any = () => {
  const { hasPermission } = CheckPermissionToPerformAction();

  const mainRoutes: RouteObject[] = protectedRoutes;
  const element = useRoutes([...mainRoutes]);
  const elementPublic = useRoutes([...publicRoutes]);
  return (
    <>
      {hasPermission() ? element : null}
      {!hasPermission() ? elementPublic : null}
    </>
  );
}

export default AppRoutes;
