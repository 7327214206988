import { RouteObject } from "react-router-dom";
import MainLayout from "../components/common/layouts/MainLayout";
import ChangePassword from "../pages/changePassword/ChangePassword";
import Dashboard from "../pages/dashboard/dashboard";
import { Documents } from "../pages/documents/routes";
import { Notes } from "../pages/notes/routes";
import { Questionnaires } from "../pages/questionnaires/routes";
import { baseRoutes } from "./base-routes";
import ProtectedRouteComponent from "./protected-component/protect-route-component";
import MainLayoutNotFound from "../components/common/layouts/MainLayoutNotFound";
// Use the basePath variable in your TypeScript code

/**
 * Routes which can only be accessed after authentication
 * @type {{}}
 *
 */
export const protectedRoutes: RouteObject[] = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "/", element: <ProtectedRouteComponent> <Dashboard /> </ProtectedRouteComponent> },
      { path: baseRoutes.dashboard.path, element: <ProtectedRouteComponent> <Dashboard /> </ProtectedRouteComponent> },
      { path: `${baseRoutes.questionnaires.path}/*`, element: <ProtectedRouteComponent> <Questionnaires /> </ProtectedRouteComponent> },
      { path: `${baseRoutes.documents.path}/*`, element: <ProtectedRouteComponent> <Documents /> </ProtectedRouteComponent> },
      { path: `${baseRoutes.notes.path}/*`, element: <ProtectedRouteComponent> <Notes /> </ProtectedRouteComponent> },
      { path: baseRoutes.changePassword.path, element: <ProtectedRouteComponent> <ChangePassword /> </ProtectedRouteComponent> },
    ],
  },
  {
    path: `/*`,
    element: <ProtectedRouteComponent> <MainLayoutNotFound /> </ProtectedRouteComponent> ,    
  }
];
