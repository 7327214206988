import { useEffect, useState } from 'react';
import { Breadcrumb, Button, Card, Image, Stack } from 'react-bootstrap';
import { MdAssignmentInd, MdOutlineFileDownload } from 'react-icons/md';
import ThemeCustomDropdown from '../../../components/common/CustomDropdown';
import DataGridCommon from '../../../components/common/dataGrid/DataGridCommon';
import { DATE_FORMAT, DELETE_DOCUMENT_CONTENT, DELETE_DOCUMENT_TITLE, HIDE_TABLE_PAGINATOR, POP_UP_CLOSE_2000 } from '../../../constants/global';
import { FaCheckCircle } from 'react-icons/fa';
import RenderAction from '../../../components/common/dataGrid/ActionCell';
import DeleteModal from '../../../components/common/DeleteModal';
import UploadDocumentModal from '../modals/UploadDocumentModal';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { questionairreActions, getCaseOfClientBasedOnClientIDAction, getApplicantOfClientBasedOnClientIDAction } from '../../../redux/questionairreModule/questionairreSlice';
import Loader from '../../../components/common/Loader';
import { Accordion, AccordionTab } from "primereact/accordion";
import { deleteCaseDocumentAction, documentActions, downloadDocumentReportAction, getDocContentByIDAction, getDocumentListAction } from '../../../redux/documentModule/documentSlice';
import InfoTooltip from '../../../components/common/InfoTooltip';
import moment from 'moment';
import ViewDocsModal from '../modals/viewDocsModal';
import EditDocumentModal from '../modals/EditDocumentModal';
import { IoIosCopy } from 'react-icons/io';
import { toast } from 'react-toastify';
import { SvgIcons } from '../../../components/common/SvgIcons';

const ListDocuments = () => {
    const [deleteShow, setDeleteShow] = useState(false);
    const [uploadDocumentModalShow, setUploadDocumentModalShow] = useState(false);
    const caseID = useSelector((state: any) => state.documentSlice.caseID);
    const isLoading = useSelector((state: any) => state.documentSlice.loading);
    const [caseOptions, setCaseOptions] = useState<any>(caseID);
    const [caseNumber, setCaseNumber] = useState<any>("");
    const [applicantsOptions, setApplicantsOptions] = useState<any>('');
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const [documentData, setDocumentData]: any = useState<any>([]);
    const [caseOptionsData, setCaseOptionsData]: any = useState<any>([]);
    const [applicantData, setApplicantData]: any = useState<any>([]);
    const [selectedCaseFolder, setSelectedCaseFolder] = useState("");
    const [editDocumentModalShow, setEditDocumentModalShow] = useState(false);
    const [docRowData, setDocRowData] = useState({});
    const [viewModalShow, setViewModalShow] = useState(false);
    const [documentView, setDocumentView] = useState(false);
    const [allIndexes, setAllIndexes] = useState<any>([])
    const [isDataReady, setIsDataReady] = useState(false);

    useEffect(() => {
        GetCasesForClient();
    }, []);

    useEffect(() => {
        if (!caseOptions) return;
        dispatch(questionairreActions.setCaseID({ caseID: caseOptions }));
        GetApplicantBasedOnCaseForClient(caseOptions);
        GetDocumentList(applicantsOptions);
    }, [caseOptions]);

    useEffect(() => {
        if (!applicantsOptions) return;
        GetDocumentList(applicantsOptions);
    }, [applicantsOptions]);

    useEffect(() => {
        if (documentData && documentData?.length > 0) {
            const indexes = documentData.map((_: any, index: number) => index);
            setAllIndexes(indexes);
            setIsDataReady(true); // Set to true when data is ready
        }
    }, [documentData]);
    // const allIndexes = documentData.map((item: any, index: any) => index);

    const GetDocumentList = (applicantsOptions: any) => {
        const payload = {
            case_id: caseOptions,
            client_id: applicantsOptions?.is_main_applicant ? applicantsOptions?.client_id ?? "" : "",
            derivates_id: !applicantsOptions?.is_main_applicant ? applicantsOptions?.derivates_id ?? "" : "",
        }
        dispatch(getDocumentListAction(payload)).then(data => {
            let dataArray: any = []
            if (data.payload.statusCode == 200) {
                data.payload.data?.forEach((el: any) => {
                    for (const key in el) {
                        const element = el[key];
                        dataArray.push({
                            id: el.id,
                            title: key.split(":")[0],
                            userName: key.split(":")[1],
                            content: element
                        })
                    }
                });
                setDocumentData(dataArray);
            }
        })
    }

    const GetCasesForClient = () => {
        dispatch(getCaseOfClientBasedOnClientIDAction()).then(data => {
            if (data.payload.statusCode == 200) {
                let cases: any = []
                data.payload.data.forEach((element: { file_number: any; id: any; case_id_number: any }) => {
                    cases.push({
                        label: element.file_number, value: element.id, case_id_number: element.case_id_number
                    });
                });
                setCaseOptionsData(cases);
                setCaseOptions(caseID ?? cases[0].id);
                if(caseID){
                    let caseIdNumber = cases.find((ele:any)=> ele.value == caseID)
                    if(caseIdNumber?.case_id_number){
                        setCaseNumber(caseNumber !== "" ? caseNumber : caseIdNumber?.case_id_number)
                    }else{
                        setCaseNumber(caseNumber !== "" ? caseNumber : cases[0].case_id_number)
                    }
                }else{
                    setCaseNumber(caseNumber !== "" ? caseNumber : cases[0].case_id_number)
                }
            }
        })
    }

    const GetApplicantBasedOnCaseForClient = (caseOptions: any) => {
        const payload = {
            case_id: caseOptions,
        }
        dispatch(getApplicantOfClientBasedOnClientIDAction(payload)).then(data => {
            if (data.payload.statusCode == 200) {
                let applicant: any = [{
                    label: "All Applicants", value: "all"
                }]
                data.payload.data.applicant_info.forEach((el: any) => {
                    applicant.push({
                        label: el.client_name, value: el
                    });
                });
                setApplicantData(applicant);
            }
        })
    }

    //Action Template
    const actionCellTemplate = (rowData: any) => {
        return (
            <RenderAction
                controlId="documents"
                rowData={rowData}
                isUploadEnable={true}
                isViewPopupEnable={true}
                isDeleteEnable={true}
                handleUploadClick={handleUploadClick}
                handleViewPopupClick={handleViewPopupClick}
                handleDeleteClick={handleDeleteClick}
                disabledUpload={false}
                // disabledUpload={rowData.status !== 'Re-requested' && rowData.status !== 'Requested'}
                disabledDelete={!rowData.created_by_client || rowData.status === 'Approved'}
            />
        );
    };
    const [deleteShowData, setDeleteShowData]: any = useState(false);

    const handleDeleteClick = (rowData: any) => {
        setDeleteShowData(rowData);
        setDeleteShow(true);
    };

    const handleViewPopupClick = (rowData: any) => {
        setDocRowData(rowData)
        setViewModalShow(true)
    }

    const handleUploadClick = (rowData: any) => {
        setDocRowData(rowData)
        setEditDocumentModalShow(true)
    }

    const sNoCellTemplate = (rowData: any, index: any) => {
        return <span className="min-w-20 d-inline-block text-center">{index?.rowIndex + 1}</span>
    }

    const requestedColTemplate = (rowData: any) => {
        return (
            <>
                {!rowData?.requested_on ?
                    <span>-</span>
                    :
                    <Stack direction="horizontal" gap={1}>
                        <span className="d-inline-flex">
                            <FaCheckCircle size={16} className="text-success" />
                        </span>
                        <div className="d-flex flex-column lh-sm">
                            <span className='text-capitalize'>{rowData?.requested_by_user?.full_name?.toLowerCase()}</span>
                            <span className="lh-1">({moment(rowData?.requested_on).format(DATE_FORMAT)})</span>
                        </div>
                    </Stack>
                }
            </>
        );
    }



    const uploadedColTemplate = (rowData: any) => {
        if (rowData.uploaded_documents_history?.length > 0 && !rowData.uploaded_by) {
            return (
                <>
                    <Stack direction="horizontal" gap={1}>
                        <span className="d-inline-flex">
                            <FaCheckCircle size={16} className="text-success" />
                        </span>
                        <div className="d-flex flex-column lh-sm">
                            <span className='text-capitalize'>{rowData.case_info?.case_client_info?.middle_name ? rowData.case_info?.case_client_info?.first_name.toLowerCase() + " " + rowData.case_info?.case_client_info?.middle_name.toLowerCase() + " " + rowData.case_info?.case_client_info?.last_name.toLowerCase() :
                                rowData.case_info?.case_client_info?.first_name.toLowerCase() +
                                " " +
                                rowData.case_info?.case_client_info?.last_name.toLowerCase()}</span>
                            <span className="lh-1">({moment(rowData?.uploaded_at).format(DATE_FORMAT)})</span>
                        </div>
                    </Stack>
                </>
            );
        } else {
            return (
                <>
                    {!rowData?.uploaded_by ?
                        <span>-</span>
                        :
                        <Stack direction="horizontal" gap={1}>
                            <span className="d-inline-flex">
                                <FaCheckCircle size={16} className="text-success" />
                            </span>
                            <div className="d-flex flex-column lh-sm">
                                <span className='text-capitalize'>{rowData?.uploaded_by_user?.full_name?.toLowerCase()}</span>
                                <span className="lh-1">({moment(rowData?.uploaded_at).format(DATE_FORMAT)})</span>
                            </div>
                        </Stack>
                    }
                </>
            );
        }

    }

    const approveColTemplate = (rowData: any) => {
        return (
            <>
                {!rowData?.approved_on ?
                    <span>-</span>
                    :
                    <Stack direction="horizontal" gap={1}>
                        <span className="d-inline-flex">
                            <FaCheckCircle size={16} className="text-success" />
                        </span>
                        <div className="d-flex flex-column lh-sm">
                            <span className='text-capitalize'>{rowData?.approved_by_user?.full_name?.toLowerCase()}</span>
                            <span className="lh-1">({moment(rowData?.approved_on).format(DATE_FORMAT)})</span>
                        </div>
                    </Stack>
                }
            </>
        );
    }

    const signedColTemplate = (rowData: any) => {
        return (
            <>
                {!rowData?.signed_on ?
                    <span>-</span>
                    :
                    <Stack direction="horizontal" gap={1}>
                        <span className="d-inline-flex">
                            <FaCheckCircle size={16} className="text-success" />
                        </span>
                        <div className="d-flex flex-column lh-sm">
                            <span className='text-capitalize'>{rowData?.signed_by_user?.full_name?.toLowerCase()}</span>
                            <span className="lh-1">({moment(rowData?.signed_on).format(DATE_FORMAT)})</span>
                        </div>
                    </Stack>
                }
            </>
        );
    }

    const statusTemplate = (rowData: any) => {
        if (rowData.status !== 'Uploaded By Agent' && rowData.status !== 'Re-requested') {
            return <span className="min-w-20 d-inline-block text-center text-capitalize">{rowData.status}</span>;
        }if(rowData?.status == "Re-requested") {
            return <span className="text-capitalize align-middle d-inline-block lh-sm min-w-100 p-1 rounded-1 text-center text-white bg-warning">{rowData.status}</span>;
        }else {
            return (
                <InfoTooltip
                    placement="auto-end"
                    className="p-0 text-body text-truncate mw-100"
                    tooltipTitle={rowData.status.replace("Agent","CH Team") ?? ""}
                    tooltipData={`Uploaded By - ${rowData.uploaded_by_user?.full_name?.toLowerCase() ?? ""}`}
                />)
        }
    }

    const documentTemplate = (rowData: any) => {
        return (
            <InfoTooltip
                placement="auto-end"
                className="p-0 text-light-emphasis text-start text-wrap border-0"
                tooltipTitle={rowData.document_name}
                tooltipData={rowData.description ? rowData.description : 'No Description Available'}
            />
        )
    }

    const columns = [
        {
            field: "s_no",
            header: "No.",
            body: sNoCellTemplate,
            headerClassName: "text-wrap",
            width: '50px',
        },
        {
            field: "document_name",
            header: "Document Name",
            body: documentTemplate
        },
        {
            field: "status",
            header: "Status",
            body: statusTemplate
        },
        {
            field: "requested",
            header: "Requested",
            bodyClassName: "theme-disabled-in-mobile",
            body: requestedColTemplate,
        },
        {
            field: "status",
            header: "Uploaded",
            bodyClassName: "theme-disabled-in-mobile",
            body: uploadedColTemplate
        },
        {
            field: "signed",
            header: "Signed",
            bodyClassName: "theme-disabled-in-mobile",
            body: signedColTemplate,
        },
        {
            field: "approved",
            header: "Approved",
            bodyClassName: "theme-disabled-in-mobile",
            body: approveColTemplate,
        },
        {
            field: "action",
            header: "Action",
            headerClassName: "theme-action-cell",
            bodyClassName: "hideEditPopupInMobile hideResetPopupInMobile hideDeleteInMobile",
            width: "150px",
            body: actionCellTemplate,
        },
    ];

    const uploadDocumentModalClick = () => {
        setUploadDocumentModalShow(true)
    }
    const [showFileData, setShowFileData] = useState<any>("");

    const openDocView = (data: any) => {
        setShowFileData("");
        setSelectedDocumentToView({})
        dispatch(documentActions.startLoader(true));
        const payload = {
            file_id: data.id
        }
        data.sharePointUrl = ""
        dispatch(getDocContentByIDAction(payload)).then(resp => {
            const webUrl = `data:${resp.payload.data?.file_mime_type};base64,${resp.payload.data.file_base64_encoded}`;
            data.sharePointUrl = webUrl;
            data.mimeType = resp?.payload?.data?.file_mime_type;
            data.file_url = resp?.payload?.data?.file_url
            setShowFileData(webUrl);
            setSelectedDocumentToView(data);
            dispatch(documentActions.stopLoader(true));
            console.log('resp.payload.data?.file_url', resp.payload.data)
        })
        setDocumentView(true);
    }

    const [selectedDocumentToView, setSelectedDocumentToView]: any = useState({});

    const handleBackToDocumentsClick = () => {
        setDocumentView(false);
    }

    const handleDownloadClick = () => {
        dispatch(documentActions.startLoader(true));
        const masterPayload = {
          id: selectedDocumentToView?.id,
        };
        dispatch(downloadDocumentReportAction(masterPayload))
          .then((data) => {
            dispatch(documentActions.stopLoader(true));
            const fileUrl = data?.payload?.data?.file_url;
            if (fileUrl) {
              const a = document.createElement('a');
              a.style.display = 'none';
              a.href = fileUrl;
              a.download = `${selectedDocumentToView?.fileName}`; // You can set the filename here
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(fileUrl);
              document.body.removeChild(a);
            }
    
          })
          .catch((err: any) => {
            console.log(err);
          })
          .finally(() => {
            dispatch(documentActions.stopLoader(true));
          });
    }

    return (
        <>
            <Loader classCustom={'theme-loader-z-index'} isLoading={isLoading} />
            <div className="d-flex flex-column pageContainer p-3 h-100 overflow-y-scroll">
                <div className="contentHeader p-1">
                    <Stack
                        direction="horizontal"
                        gap={2}
                        className="flex-wrap justify-content-between pb-2"
                    >
                        <h1 className="fw-semibold h4 mb-0">Documents</h1>
                        <Breadcrumb className="fw-semibold">
                            <Breadcrumb.Item active>Documents</Breadcrumb.Item>
                        </Breadcrumb>
                    </Stack>
                </div>
                <div className="flex-grow-1 pageContent position-relative pt-4 d-flex flex-column">
                    {!documentView ?
                        <Card className="bg-white shadow-lg border-0 theme-card-cover flex-grow-1">
                            <div className="theme-card-header px-1">
                                <Stack
                                    direction="horizontal"
                                    gap={3}
                                    className="flex-wrap px-3 pt-3 pb-2 align-items-start"
                                >
                                    <h5 className="mb-0 position-relative fw-normal me-auto">
                                        <div className="align-items-center bg-secondary d-inline-flex h-60 justify-content-center position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                                            <MdAssignmentInd size={32} />
                                        </div>
                                        Documents List
                                    </h5>
                                    <Stack
                                        direction="horizontal"
                                        gap={3}
                                        className="flex-wrap ms-md-auto theme-filter-col-2"
                                    >
                                        <div className="w-220">
                                            <ThemeCustomDropdown
                                                controlId="case_id"
                                                placeHolder="Case#"
                                                data={caseOptionsData}
                                                value={caseOptions}
                                                handleFilter={(e: any) => {
                                                    setCaseNumber(caseOptionsData.find((i: { value: any; }) => {
                                                        return i.value === e.value
                                                    }).case_id_number)
                                                    setCaseOptions(e.value);
                                                    setIsDataReady(false)
                                                }}

                                            />
                                        </div>
                                        <div className="w-220">
                                            <ThemeCustomDropdown
                                                controlId="all_applications"
                                                placeHolder="All Applicants"
                                                data={applicantData}
                                                value={applicantsOptions}
                                                handleFilter={(e: any) => {
                                                    setApplicantsOptions(e.value);
                                                }}
                                                showClear={true}
                                            />
                                        </div>
                                        <Button
                                            variant="primary"
                                            onClick={uploadDocumentModalClick}
                                            className="text-nowrap"
                                            disabled={!caseOptions}
                                        >
                                            Upload New
                                        </Button>
                                    </Stack>
                                </Stack>
                            </div>

                            <div className="px-20 pt-1 pb-4">
                                <div className="theme-accordion-cover">
                                    {
                                        isDataReady && 
                                        <Accordion
                                            multiple
                                            activeIndex={allIndexes}
                                        >
                                            {documentData?.map((documentTableData: any) => {
                                                return (
                                                    <AccordionTab
                                                        key={documentTableData.questionnaire_id}
                                                        headerTemplate={
                                                            <Stack
                                                                direction="horizontal"
                                                                gap={2}
                                                                className="flex-fill pe-3 mw-1"
                                                            >
                                                                <div className="d-inline-flex theme-accordion-title mw-1">
                                                                    <h5 className="text-primary fw-bold mb-0 mw-100 pe-1 text-truncate">{documentTableData?.title}: <span className="fw-normal text-capitalize">{documentTableData?.userName?.toLowerCase()}</span></h5>
                                                                </div>
                                                            </Stack>
                                                        }
                                                    >
                                                        <div className="theme-accordion-data">
                                                            <DataGridCommon
                                                                columns={columns}
                                                                data={documentTableData?.content}
                                                                HideTablePaginator={HIDE_TABLE_PAGINATOR}
                                                            />
                                                        </div>
                                                    </AccordionTab>
                                                )
                                            })}
                                        </Accordion>
                                    }
                                   
                                    {documentData.length === 0 ? 'No Document found' : ''}
                                </div>
                            </div>
                        </Card> :
                        <>
                            {/* Documents View */}
                            <Card className="h-100 bg-white shadow-lg border-0 mb-3 theme-card-cover">
                                <div className="px-1 pt-1">
                                    <Stack
                                        direction="horizontal"
                                        gap={3}
                                        className="flex-wrap px-3 py-2 align-items-start"
                                    >
                                        <h5 className="fw-semibold mb-0 py-sm-2">
                                            {selectedDocumentToView?.fileName}
                                        </h5>
                                        
                                        <Stack
                                            direction="horizontal"
                                            gap={2}
                                            className="flex-wrap ms-sm-auto"
                                        >
                                            <Button
                                                variant="outline-primary"
                                                onClick={handleDownloadClick}
                                            >
                                                <MdOutlineFileDownload
                                                    size={18}
                                                    className="me-2 align-text-top"
                                                />
                                                Download
                                            </Button>
                                            <Button
                                                variant="outline-primary"
                                                onClick={handleBackToDocumentsClick}
                                            >
                                                Back To Documents
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </div>
                                <div className="flex-grow-1 d-flex flex-column px-3 pb-3 pb-1 overflow-auto">
                                    <div className="p-1 h-100 text-center">
                                        {/* {selectedDocumentToView.mimeType === 'application/pdf' || selectedDocumentToView.mimeType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ?
                                            <div className='ratio ratio-16x9'>
                                                <iframe height={500} width={800} src={showFileData} title={selectedDocumentToView?.fileUrl} />
                                            </div>
                                            :
                                            <Image src={showFileData} width={330} height={60} fluid />} */}
                                            <iframe height={500} width={800} src={selectedDocumentToView?.file_url} title={selectedDocumentToView?.file_url} />
                                    </div>
                                </div>
                            </Card>
                        </>
                    }
                </div>
            </div>

            <ViewDocsModal
                show={viewModalShow}
                handleClose={() => setViewModalShow(false)}
                docRowData={docRowData}
                openDocView={openDocView}
            />

            <UploadDocumentModal
                show={uploadDocumentModalShow}
                handleClose={() => {
                    GetDocumentList(applicantsOptions);
                    setUploadDocumentModalShow(false)
                }}
                selectedCaseFolder={selectedCaseFolder}
                docRowData={docRowData}
                caseOptions={caseOptions}
                caseNumber={caseNumber}
                caseID={caseID}
            />

            {/* Edit Document Modal */}
            <EditDocumentModal
                show={editDocumentModalShow}
                handleClose={() => {
                    GetDocumentList(applicantsOptions);
                    setEditDocumentModalShow(false)
                }}
                selectedCaseFolder={selectedCaseFolder}
                docRowData={docRowData}
                caseOptions={caseOptions}
                caseNumber={caseNumber}
            />



            <DeleteModal
                show={deleteShow}
                handleClose={() => setDeleteShow(false)}
                deleteModalHeaderTitle={DELETE_DOCUMENT_TITLE}
                deleteModalBodyContent={DELETE_DOCUMENT_CONTENT}
                handleDeleteClose={() => setDeleteShow(false)}
                customDeleteFunction={() => {
                    const payload = {
                        "case_id": caseID,
                        "doc_id": deleteShowData?.id
                    }
                    dispatch(deleteCaseDocumentAction(payload)).then((data): void => {
                        setDeleteShow(false)
                        GetDocumentList(applicantsOptions);
                    }).catch((err: any) => {
                        console.log(err);
                    });
                }}
                deleteRowData={deleteShowData}
            />
        </>
    )
}

export default ListDocuments